//find all forms where class="disabled" and disable them

    var forms = document.querySelectorAll('form.disabled');
    forms.forEach(function(form) {
        form.addEventListener('submit', function(e) {
            e.preventDefault();
        });
        //find all inputs, selects and textareas and disable them
        var inputs = form.querySelectorAll('input, select, textarea');
        inputs.forEach(function(input) {
            input.disabled = true;
        });
        //find all buttons with type is submit and .btn and disable them and add class "disabled"
        var buttons = form.querySelectorAll('button[type="submit"]');
        buttons.forEach(function(button) {
            button.disabled = true;
            button.classList.add('disabled');
        });

        //find all .btn-group a type=button
        var buttons = form.querySelectorAll('.btn-group a[type="button"]:not(.btn)');
        buttons.forEach(function(button) {
            button.disabled = true;
            button.classList.add('disabled');
            button.removeAttribute('onclick');
            button.removeAttribute('href');
        });


    });
