//find all .modal-link elements and add click event
document.querySelectorAll('.link-modal').forEach(item => {
    item.addEventListener('click', event => {
        event.preventDefault();
        let modal = new LinkModal(item);
        modal.handle();
    });
});

//add click events to #modal-default and #modal-default-close to add hidden to it's classList
document.querySelector('#modal-default-close').addEventListener('click', event => {
    event.preventDefault();
    document.querySelector('#modal-default').classList.add('hidden');
});
document.querySelector('#modal-default-cross').addEventListener('click', event => {
    event.preventDefault();
    document.querySelector('#modal-default').classList.add('hidden');
});


//create class LinkModal
class LinkModal {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.url = this.element.getAttribute('href');
        //look for data-element attribute
        this.targetElement = this.element.getAttribute('data-element');
        this.title = this.element.getAttribute('data-title');
    }

    handle() {
        //fetch the url
        fetch(this.url)
            .then(response => response.text())
            .then(data => {
                this.process(data);

            });
    }

    process(data) {
        document.querySelector('#modal-default-content').classList.remove('titled');

        let content = data;
        let parser = new DOMParser();
        let doc = parser.parseFromString(data, 'text/html');

        if(this.targetElement){
            let element = doc.querySelector(this.targetElement);
            content = element.innerHTML;
        }
        if (this.title) {
            let title = doc.querySelector(this.title);
            document.querySelector('#modal-default-title').innerHTML = title.innerHTML;
            document.querySelector('#modal-default-content').classList.add('titled');
        }

        //add the content to #modal-default-content
        document.querySelector('#modal-default-content').innerHTML = content;



       // document.querySelector('#modal-default-content').style.height =  + 'px';

        //remove hidden from #modal-default
        document.querySelector('#modal-default').classList.remove('hidden');

        let maxHeight =document.querySelector('#modal-default-container').clientHeight - 60;
        document.querySelector('#modal-default-content').style.maxHeight = maxHeight + 'px';
    }

}