document.querySelector('.menu-toggle').addEventListener('click', function() {
    Menu.toggle();
});

//set transition duration to 500ms
document.documentElement.style.setProperty('--nav-transition-duration', '1500ms');

class Menu {
    static originalWidth = '400px';
    static toggle() {
        //get css variable "nav-width" from root
        let navWidth = getComputedStyle(document.documentElement).getPropertyValue('--nav-width');
        console.log(navWidth, Menu.originalWidth, navWidth==Menu.originalWidth);
        if(navWidth==Menu.originalWidth) {
            document.documentElement.style.setProperty('--nav-width', '0px');
        }
        else {
            document.documentElement.style.setProperty('--nav-width', Menu.originalWidth);
        }

        console.log(navWidth);
    }
}